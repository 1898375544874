<template>
  <div>
    <MainBanner />

    <div class="container py-8 mx-auto text-center">
      <h1 class="pb-6 text-3xl md:text-4xl open-semi">
        Message Sending Successful!
      </h1>
      <p class="px-4 text-base md:text-lg md:px-0 open-normal">
        Thank you for your kind interest in Hytech Office Automation (M) Sdn
        Bhd. We will get back to you as soon as possible.
      </p>
      <router-link to="/" class="block w-48 mx-auto">
        <p
          class="block py-2 my-5 bg-blue-1 rounded-md text-white"
        >
          Back To Home
        </p>
      </router-link>
    </div>
  </div>
</template>

<script>
import MainBanner from "@/components/MainBanner.vue";

export default {
  components: {
    MainBanner,
  },
};
</script>

<style></style>
